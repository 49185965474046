import React, { createContext, useReducer, useEffect, useRef } from 'react';
import FleetManager from "./FleetManager";
import { lastSevenDays } from "./util/dates";
import { getVehicles, getDailyTotalAll } from './api/vehicle';
import { getClients } from './api/client';

export const Context = createContext(null);

const ContextHandler = ({ handleLogout }) => {

  const reducer = (state, action) => {
    console.log("reducer " + action.type + " " + JSON.stringify(action).substring(0, 300));
    switch (action.type) {
      case "refresh":
        return { ...state, refreshCount: state.refreshCount+1 };
      case "setState":
        return { ...state, [action.attribute]: action.payload };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, { refreshCount: 0, dateRange: lastSevenDays() } );

  useEffect(() => {
    getVehicles().then(result => dispatch({ type: 'setState', attribute: 'assets', payload: result.data.sort((a, b) => a.name?.localeCompare(b.name)) }));
    getClients() .then(result => dispatch({ type: 'setState', attribute: 'clients', payload: result.data.sort((a, b) => a.name?.localeCompare(b.name)) }));
  }, []);
  
  return (
    <Context.Provider value={ { ...state, dispatch } }>
      <FleetManager handleLogout={handleLogout} />
    </Context.Provider>
  );
};

export default ContextHandler;

