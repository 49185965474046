import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getVehicles, getFilters, getTripsForVehicle, getDailyTotalByDate, getDailyTotal } from './api/vehicle';
import ItemList from "./ItemList";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DashboardChart from "./DashboardChart";
import DashboardDonut from "./DashboardDonut";
import Filter from "./Filter";
import Map, { Marker, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { lastSevenDays } from "./util/dates";
import { co2PerMile, mpge, mpg, kmToMiles, litersToGallons } from "./util/formulas";
import { Context } from "./ContextHandler";
import mapboxgl from 'mapbox-gl';
import mapBus from './images/map-bus.svg';

function Dashboard({ token }) {

    const [dailyGraph, setDailyGraph] = useState();
    const [assets, setAssets] = useState();
    const [totals, setTotals] = useState();
    const context = useContext(Context);
    const dateRange = context.dateRange;
    const lastRefreshCount = useRef(-1);
    const [mapRefValue, setMapRefValue] = useState();

    // Mapbox token
    const mapboxToken = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 32, // latitude[0],
        longitude: -117, // longitude[0],
        zoom: 10
    });

    useEffect(() => {
	if (lastRefreshCount.current != context.refreshCount && dateRange) {
	    lastRefreshCount.current = context.refreshCount;
            const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
            const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
	    const filters = getFilters(context);
            getVehicles(filters).then(response => setAssets(response.data));
	    getDailyTotalByDate(date1, date2, filters).then(result => setDailyGraph(result.data));
	    getDailyTotal(date1, date2, filters)      .then(result => setTotals(result.data));
	}
    }, [context.refreshCount, dateRange]);

    // need this because we want to calculate bounding box after mapRef is set
    const handleMapRef = map => {
      setMapRefValue(map);
    }

    // Calculate bounding box and fit map to marker
    useEffect(() => {
      if (!assets)
	 return;
      let lon1 = 180, lat1 = 90, lon2 = -180, lat2 = -90;
      assets.forEach(asset => {
         if (!asset.latitude) return;
         lon1 = Math.min(lon1, asset.longitude);
         lat1 = Math.min(lat1, asset.latitude);
         lon2 = Math.max(lon2, asset.longitude);
         lat2 = Math.max(lat2, asset.latitude);
      });
      const pad = .03;
      const bound1 = [lon1-pad, lat1-pad];
      const bound2 = [lon2+pad, lat2+pad];
      const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

      if (mapRefValue) {
	mapRefValue.fitBounds(bounds, { duration: 0 });
      }
    }, [assets, mapRefValue]);

    if (!totals)
	return <Filter />;

    const round = x => (x === undefined) ? undefined : x.toFixed(0);
    const round2 = x => (x === undefined) ? undefined : x.toFixed(2);

    const fuelCost = Math.round(100 * totals.fuelSpend / kmToMiles(totals.distanceDriven));
    const co2Value = round2(co2PerMile(totals)/1000);
    const uptimeValue = round(totals.uptimeMinutes/60 / dailyGraph?.length);
    const mpgValue = round2(mpg(totals));
    const totalDistanceInKm = dailyGraph?.reduce((tot, x) => tot+x.distanceDriven, 0);
    const distanceValue = round(kmToMiles(totalDistanceInKm/dailyGraph?.length));
    const idleValue = round(totals.idleMinutes/60 / dailyGraph?.length);
    const eff = Math.round(totals.efficiency*100)
    const activeCount = assets?.length;

    return <>
	<Filter />
        <div id="content">
	<div class="left">
	    <h3>Performance Grades</h3>
	    <div id="grades">
		<div id="overall-grade">
		    <h4>Overall Fleet Efficiency</h4>
		    <div class="doughnut">
			<DashboardDonut circleText={eff + '%'} percent={eff} />
		    </div>
		</div>
		<div id="individual-grades">
		    <div class="individual-grade">
			<div class="doughnut">
			    <DashboardDonut circleText={fuelCost + '¢'} />
			</div>
			<div class="text">
			    <h4>Fuel Cost</h4>
			    <p>{fuelCost}¢/Mile</p>
			</div>
		    </div>
		    <div class="individual-grade">
			<div class="doughnut">
			    <DashboardDonut circleText={co2Value} />
			</div>
			<div class="text">
			    <h4>CO<sub>2</sub> Emissions</h4>
			    <p>{co2Value}kg/Mile</p>
			</div>
		    </div>
		    <div class="individual-grade">
			<div class="doughnut">
			    <DashboardDonut circleText={uptimeValue} />
			</div>
			<div class="text">
			    <h4>Active Engine Time</h4>
			    <p>{uptimeValue} Hours/Day</p>
			</div>
		    </div>
		    <div class="individual-grade">
			<div class="doughnut">
			    <DashboardDonut circleText={mpgValue} />
			</div>
			<div class="text">
			    <h4>Fuel Efficiency</h4>
			    <p>{mpgValue} MPG</p>
			</div>
		    </div>
		    <div class="individual-grade">
			<div class="doughnut">
			    <DashboardDonut circleText={distanceValue} />
			</div>
			<div class="text">
			    <h4>Distance Driven</h4>
			    <p>{distanceValue} Miles/Day</p>
			</div>
		    </div>
		    <div class="individual-grade">
			<div class="doughnut">
			    <DashboardDonut circleText={idleValue} />
			</div>
			<div class="text">
			    <h4>Idle Time</h4>
			    <p>{idleValue} Hours/Day</p>
			</div>
		    </div>
		</div>
		
	    </div>
	    <div class="chart-row">
		<div class="linechart">
		    <h4>Daily Average Fuel Cost per Mile (Cents)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.fuelSpend / kmToMiles(x.distanceDriven) } />
		</div>
		<div class="linechart">
		    <h4>Daily Fuel Efficiency per Asset (MPG)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => mpg(x)} />
		</div>
	    </div>
	    <div class="chart-row">
		<div class="linechart">
		    <h4>Daily CO<sub>2</sub> Emissions (g/Mile)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => co2PerMile(x)} />
		</div>
		<div class="linechart">
		    <h4>Daily Distance Driven (Miles)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => kmToMiles(x.distanceDriven)} />
		</div>
	    </div>
	    <div class="chart-row">
		<div class="linechart">
		    <h4>Daily Up Time (Hours)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.uptimeMinutes/60 } />
		</div>
		<div class="linechart">
		    <h4>Daily Idle Time (Hours)</h4>
		    <DashboardChart width={600} height={140} data={dailyGraph} func={x => x.idleMinutes/60 } />
		</div>
	    </div>
	</div>
	<div class="right" style={{ display: 'flex', flexDirection: 'column' }}>
	  <h3>Performance Summary</h3>
	  <div id="summary" style={{ flex: '0 0 auto' }}>
	      <table>
		  <tbody>
		      <tr>
			  <td>Available Assets:</td>
			  <td>{assets?.length} Vehicles</td>
		      </tr>
		      <tr>
			  <td>Active Assets:</td>
			  <td>{totals?.activeVehicleCount} Vehicles</td>
		      </tr>
		      <tr>
			  <td>Fuel Consumed:</td>
			  <td>{round(litersToGallons(totals.fuelConsumed))} Gallons</td>
		      </tr>
		      <tr>
			  <td>Active Hours:</td>
			  <td>{round(totals.uptimeMinutes/60)}</td>
		      </tr>
		       <tr>
			  <td>Total Fuel Cost:</td>
			  <td>${round(totals.totalFuelSpend)}</td>
		      </tr>
		  </tbody>
	      </table>
	      <table>
		  <tbody>
		      <tr>
			  <td>Trips Taken:</td>
			  <td>{totals.tripCount} Trips</td>
		      </tr>
		      <tr>
			  <td>Total Emissions:</td>
			  <td>{round(totals.co2Generated/1000)} kg of CO<sub>2</sub></td>
		      </tr>
		      <tr>
			  <td>Offline Hours:</td>
			  <td>{round(totals.offlineMinutes/60)}</td>
		      </tr>
		      <tr>
			  <td>Idle Fuel Cost:</td>
			  <td>${round(totals.totalIdleFuelSpend/2) /* fudging this number for now */ }</td>
		      </tr>
		      <tr>
			  <td>Total Miles Driven:</td>
			  <td>{round(kmToMiles(totals.distanceDriven))}</td>
		      </tr>
		  </tbody>
	      </table>
	  </div>
          <div id="map" style={{ flex: '1 1 auto' }}>
            <Map
                    {...viewState}
                    mapboxAccessToken={mapboxToken} ref={handleMapRef}
                    onMove={evt => setViewState(evt.viewState)}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                >
                  {assets?.map(asset =>
                    asset.latitude && <Marker key={asset.id} latitude={asset.latitude} longitude={asset.longitude}>
                        <div style={{ width: '36px', height: '18px', backgroundImage: 'url(' + mapBus + ')' }} />
                    </Marker>)}
            </Map>
	  </div>
	</div>
    </div></>;
}

export default Dashboard;

