import React, { useState, useEffect } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import UserList from './UserList';
import ClientList from './ClientList';
import VehicleList from './VehicleList';
import AssetList from './AssetList';
import AssetDetails from './AssetDetails';
import TripList from './TripList';
import TripDetails from './TripDetails';
import RouteView from './RouteView';
import RoutePage from './RoutePage';
import ZonePage from './ZonePage';
import Dashboard from './Dashboard';
import axios from 'axios';
import 'rsuite/DateRangePicker/styles/index.css';
import './styles.css';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';

function FleetManager({ handleLogout }) {
   const name = localStorage.getItem('name');
   const initials = name.split(' ').map(x => x.substr(0, 1)).join('');
   const [isPopupVisible, setPopupVisible] = useState(false);

   const getContent = (selection, cls, content) => (
          <div id="main" class={cls}>
            <div id="nav">
                <ul>
                    <li class={selection == 'dashboard' ? 'home selected' : 'home'}><Link to="/">Home</Link></li>
                    <li class={selection.startsWith('asset') ? 'assets selected' : 'assets'}><Link to="/assets">Assets</Link></li>
                    <li class={selection.startsWith('trips') ? "trips selected" : "trips"}><Link to="/trips">Trips</Link></li>
                    <li class={selection.startsWith('zones') ? "zones selected" : "zones"}><Link to="/zones">Zones</Link></li>
                </ul>
                <div class="settings"><a href="settings.html">Settings</a></div>
            </div>
            <div id="focus">
                {content}
            </div>
         </div>
  );

  return (
    <Router>
        <header>
            <Link id='logo' to="/"><img src={logo} /></Link>
            <div id="account" class="dropdown" onMouseEnter={ev => setPopupVisible(true)} onMouseLeave={ev => setPopupVisible(false)}>
                <span>{initials}</span>
                {isPopupVisible && <div class="dropdown-content">
                    <span>{name}</span>
                    <a href="#">Account</a>
                    <a href="#" onClick={handleLogout}>Sign out</a>
                </div>}
            </div>
            <a id="help" href="help.html"><img src={help} /></a>
        </header>
	<Routes>
	  <Route path="/assets/:id" element={getContent('asset', 'asset-details', <AssetDetails />)} />
	  <Route path="/assets"     element={getContent('assetList', 'assets', <AssetList />)} />
	  <Route path="/trips/:id"  element={getContent('trips',     'trips',  <TripDetails />)} />
	  <Route path="/trips"      element={getContent('tripsList', 'trips',  <TripList />)} />
	  <Route path="/route/:ind/:m/:d/:st/:tl"      element={getContent('route',     'route',  <RoutePage />)} />
	  <Route path="/route"      element={getContent('route',     'route',  <RoutePage />)} />
	  <Route path="/zones"      element={getContent('zones',     'zones',  <ZonePage />)} />
	  <Route path="/"           element={getContent('dashboard', 'home',   <Dashboard />)} />
	</Routes>
    </Router>
  );
}

export default FleetManager;

