import React, { useState, useEffect } from 'react';
import { getUsers, addUser, updateUser } from './api/user';
import ItemList from "./ItemList";

function UserList({ token }) {
  const columns = [ { key: 'name' }, { key: 'username' } ];
  const fields = [ { key: 'name' }, { key: 'username' }, { key: 'password' } ];

  return <ItemList columns={columns} fields={fields} addItem={addUser} getItems={getUsers} updateItem={updateUser} itemType="User" />;
}

export default UserList;

