import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getDailyTotalAll } from './api/vehicle';
import ItemList from "./ItemList";
import { Link } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import Filter from "./Filter";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";

function AssetList({ token }) {
  const [assets, setAssets] = useState();
  const [totals, setTotals] = useState();
  const [combinedAssets, setCombinedAssets] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRange;
  const lastRefreshCount = useRef(-1);

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;
  if (totals) {
    totals.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }

  let avgFuelCost, avgCo2, avgMpg, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed;
  if (assets && totals) {
    const noNaN = x => (x === undefined ? 0 : x)
    const assetCount = totals.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
    avgFuelCost = totals.reduce((part, a) => part+noNaN(a.fuelSpend),    0)/assetCount;
    avgCo2      = totals.reduce((part, a) => part+co2PerMile(a),         0)/assetCount;
    totalDistance = totals.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
    totalFuelConsumed = totals.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
    avgDistance = totalDistance/assetCount;
    distPerDay  = totals.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
    const assetCountEff = totals.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
    avgEff      = totals.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
    avgMpg = kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed));
  }
  const eff = (avgEff*100).toFixed(0);

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const filters = getFilters(context);
      getVehicles(filters).then(response => setAssets(response.data));
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
      getDailyTotalAll(date1, date2, filters).then(response => setTotals(response.data));
    }
  }, [context.refreshCount, dateRange]);

  useEffect(() => {
    if (assets && totals) {
      const combined = assets.map(asset => {
        const total = totals?.find(x => x.vehicleId == asset.id);
        return { ...asset, totals: total }
      });
      setCombinedAssets(combined);
    }
  }, [assets, totals]);

  const columns = [
		    { header: 'Asset', key: 'name', width: '12%' },
		    { header: 'Map', key: 'map', sortable: false, displayOnly: true },
		    { header: 'Group', key: 'group' },
		    { header: 'Make / Model', key: 'makeModel' },
		    { header: 'Year', key: 'year' },
		    { header: 'Fuel', key: 'fuelType' },
		    { header: 'Trips', key: 'tripCount' },
		    { header: 'Distance Traveled', key: 'milesDriven' },
		    { header: 'Duration of Trips (Minutes)', key: 'tripMinutes' },
		    { header: 'Utilization', sortable: false, width: '7%', key: 'utilization', displayOnly: true },
		    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
		    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
		    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
		    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
		    { header: 'Fuel Consumed', key: 'fuelConsumed' },
		    //{ header: 'Fuel / Trip', key: 'fuelPerTrip' },
		    { header: 'MPG', key: 'mpg' },
		    { header: 'Fuel Cost', key: 'fuelSpend' },
		    //{ header: 'Cost / Mile', key: 'costPerMile' },
		    //{ header: 'Cost / Day', key: 'costPerDay' },
		    //{ header: 'Cost / Trip', key: 'costPerTrip' },
		    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
		    //{ header: <>CO<sub>2</sub> (g/Mile)</>, downloadHeader: 'CO2 (g/Mile)', key: 'co2PerMile' },
		    //{ header: 'Avg MPH', key: 'averageSpeed' },
		    //{ header: 'Elev Gain (ft)', key: 'elevGain' },
		    //{ header: 'Grade', key: 'grade' }
		    { header: 'Efficiency', key: 'efficiency' },
  ];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getAssetInfo = (asset, key) => {
    if (key == 'name')
      return asset.name || asset.licensePlate || asset.vin || asset.id.toString();
    if (key == 'makeModel')
      return asset.make + ' ' + asset.model;
    if (key == 'group')
      return asset.groups?.map(x => x.name).join(', ')
    if (key == 'upTimeHours')
      return asset.totals?.uptimeMinutes / 60;
    if (key == 'milesDriven')
      return kmToMiles(asset.totals?.distanceDriven);
    if (key == 'tripCount')
      return asset.totals?.tripCount;
    if (key == 'fuelConsumed')
      return litersToGallons(asset.totals?.fuelConsumed);
    if (key == 'fuelPerTrip')
      return litersToGallons(asset.totals?.fuelConsumed / asset.totals?.tripCount);
    if (key == 'mpge')
      return mpge(asset.totals);
    if (key == 'mpg')
      return mpg(asset.totals);
    if (key == 'fuelSpend')
      return asset.totals?.fuelSpend;
    if (key == 'costPerMile')
      return asset.totals?.fuelSpend / kmToMiles(asset.totals?.distanceDriven);
    if (key == 'costPerDay')
      return asset.totals?.fuelSpendPerDay;
    if (key == 'costPerTrip')
      return asset.totals?.fuelSpend / asset.totals?.tripCount;
    if (key == 'co2e')
      return co2eInKg(asset.totals);
    if (key == 'co2PerMile')
      return co2PerMile(asset.totals);
    if (key == 'averageSpeed')
      return asset.totals?.averageSpeed;
    if (key == 'elevGain')
      return 321.1;
    if (key == 'acceleratingMinutes' || key == 'deceleratingMinutes' || key == 'idleMinutes' || key == 'offlineMinutes' || key == 'efficiency' || key == 'tripMinutes')
      return asset.totals && asset.totals[key];
    return asset[key];
  }

  const getAssetCell = (asset, key) => {
    if (key == 'utilization') {
      let offWidth = 0, idleWidth = 0, decWidth = 0, accWidth = 0;
      const total = asset.totals;
      if (total) {
	  let totalMins = total.acceleratingMinutes + total.deceleratingMinutes + total.idleMinutes + total.offlineMinutes;
	  offWidth = 100*total.offlineMinutes / totalMins;
	  idleWidth = 100*total.idleMinutes / totalMins;
	  decWidth = 100*total.deceleratingMinutes / totalMins;
	  accWidth = 100*total.acceleratingMinutes / totalMins;
      }
      return <td class="chart"><span class="offline" data-title={getLabel("Offline", offWidth, total?.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span class="idle" data-title={getLabel("Idle", idleWidth, total?.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span class="decelerating" data-title={getLabel("Decelerating", decWidth, total?.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span class="accelerating" data-title={getLabel("Accelerating", accWidth, total?.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    if (key == 'map')
      return <td class="map"><a class="moving" data-title="Moving - Map current location" href="asset-details.html"></a></td>
    let val = getAssetInfo(asset, key);
    if (key == 'name')
      return <td class="text"><Link to={'/assets/' + asset.id}>{val}</Link></td>
    if (key == 'efficiency' && val)
      return <td class="number">{(val * 100).toFixed(0)}%</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    return <td class="text">{val}</td>;
  }

  return <>
            <div id="focus">
                <Filter />
                <div id="content">
                    <div id="charts">
                        <div class="individual-chart small">
			    <h4>Efficiency</h4>
			    <div class="doughnut">
				  <AssetListDonut mainText={eff + '%'} subText={"Efficient"} percent={eff} />
			    </div>
			</div>
                        <div class="individual-chart">
                            <h4>Fleet Utilization</h4>
                            <div class="doughnut">
                                <UtilizationDonut accelerating={accMin} decelerating={decMin} idle={idleMin} offline={offMin} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Fuel Cost</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round0(100*avgFuelCost/kmToMiles(avgDistance)) + '¢'} subText={"¢/Mile"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>CO<sub>2</sub> Emissions</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round(avgCo2/1000)} subText={"kg/Mile"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Fuel Efficiency</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round(avgMpg)} subText={"MPG"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Distance Driven</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round0(kmToMiles(distPerDay))} subText={"Miles/Day"} />
                            </div>
                        </div>
                    </div>
                    <PagedList items={combinedAssets} columns={columns} getItemInfo={getAssetInfo} getItemCell={getAssetCell} itemType="Assets" />
                </div>
            </div>
         </>;
}

export default AssetList;

