import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function ItemList({ token, getItems, addItem, updateItem, itemType, columns, fields, onSelect }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editItemData, setEditItemData] = useState({ });
  const [sortConfig, setSortConfig] = useState({ key: columns[0].key, direction: 'asc' });
  const [adding, setAdding] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      getItems().then(response => setItems(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectItem = (item) => {
    if (onSelect)
      onSelect(item);
    else
      setSelectedItem(item);
  };

  const handleEdit = () => {
    setEditMode(true);
    setEditItemData(selectedItem);
  };

  const handleTrips = () => {
    navigate('/trips/' + selectedItem.id);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditItemData({ ...editItemData, [name]: value });
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSubmitEdit = async () => {
    try {
      updateItem(selectedItem.id, editItemData).then(result => {
         console.log(result);

         // Refresh the item list after successful update
         fetchItems();
         setEditMode(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = [...items].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const handleAddItem = async () => {
    setAdding(true);
    setEditItemData({});
  };

  const handleCancelAddItem = () => {
    setAdding(false);
  };

  const handleSubmitNewItem = async () => {
    try {
      addItem(editItemData).then(result => {
         console.log(result);

         // Refresh the item list after successful update
         fetchItems();
         setAdding(false);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    if (editMode)
      handleCancelEdit();
    else
      handleCancelAddItem();
  }

  const handleSubmit = () => {
    if (editMode)
      handleSubmitEdit();
    else
      handleSubmitNewItem();
  }

  function capitalize(string) {
     return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getColumn = (item, col) => {
    if (col.format)
      return col.format(item[col.key], item, col);
    else
      return item[col.key];
  }

  return (
    <div>
      <h1>{itemType} List</h1>
      <button onClick={handleAddItem}>Add {itemType}</button>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr style={{ backgroundColor: '#f2f2f2' }}>
            {columns.map(col => 
		<th style={{ border: '1px solid #ddd', padding: '8px', cursor: 'pointer' }} onClick={() => handleSort(col.key)}>
		  {col.header || capitalize(col.key)} {sortConfig.key === col.key && (sortConfig.direction !== 'asc' ? <>&#9650;</> : <>&#9660;</>)}
		</th>
             )}
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item) => (
            <tr key={item.id} onClick={() => handleSelectItem(item)}
                style={{ cursor: 'pointer', backgroundColor: selectedItem && selectedItem.id === item.id ? 'lightblue' : 'transparent' }}>
              {columns.map(col => <td style={{ border: '1px solid #ddd', padding: '8px' }}>{getColumn(item, col)}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
      {selectedItem && !editMode && (
        <>
          {updateItem && <button onClick={handleEdit}>Edit</button>}
          {itemType === 'Vehicle' && <button onClick={handleTrips}>View Trips</button>}
        </>
      )}
      {(adding || (selectedItem && editMode)) && (
        <div>
          <h2>{adding ? 'Add New ' + itemType : 'Edit ' + itemType}</h2>
          {fields.map(field =>
	      <div>
		<label htmlFor={field.key}>{field.label || capitalize(field.key)}:</label>
		<input type="text" id={field.key} name={field.key} value={editItemData[field.key]} onChange={handleInputChange} />
	      </div>
          )}
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}
    </div>
  );
}

export default ItemList;

