import React, { useState, useEffect } from 'react';
import { getVehicle, getNewTripsForVehicle } from './api/vehicle';
import ItemList from "./ItemList";
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Legend, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

function UtilizationDonut({ accelerating, decelerating, idle, offline, showIdle }) {

        const fleet_utilization_data = {
            labels: [
                'Accelerating','Decelerating','Idle','Offline'
            ],
            datasets: [{
              data: [accelerating, decelerating, idle, offline],
              backgroundColor: [
                '#F85E7D','#B14EC5','#F78957','#7C7C7F'
              ],
              borderWidth: 0
            }]
        }

	if (!accelerating)
	    return;

	const uptimePercent = Math.round((accelerating+decelerating+idle)*100/(accelerating+decelerating+idle+offline));
	const idlePercent = Math.round(idle*100/(accelerating+decelerating+idle+offline));

        const fleet_utilization_number = {
            id: 'doughnutNumber',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

                ctx.save();
                const xCoor = chart.getDatasetMeta(0).data[0].x;

		// should share code with AssetListDonut here
                const yCoor = chart.getDatasetMeta(0).data[0].y - 47/2 + 30/2;
                ctx.font = 'bold 1.5vi Helvetica, "Helvetica Neue", Arial, sans-serif';
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle'
                ctx.fillText((showIdle ? idlePercent : uptimePercent) + '%', xCoor, yCoor);
            }
        }

        
        const large_doughnut_options = {
            cutout: '65%',
            aspectRatio: 1.7, // overflows container if I make this too small
            plugins: {
                tooltip: {
                    bodyFont: {
                        size: 24
                    }
                },
                legend: {
                    display: true,
                    position: 'right',
                    labels: {
                        boxWidth: 10,
                        boxHeight: 10
                    }
                }
            }
        }

        const fleet_utilization_label = {
            id: 'doughnutLabel',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

                ctx.save();
                const xCoor = chart.getDatasetMeta(0).data[0].x;
                const yCoor = chart.getDatasetMeta(0).data[0].y + 47/2 - 15/2;
                ctx.font = 'normal 0.7vi Helvetica, "Helvetica Neue", Arial, sans-serif';
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle'
                ctx.fillText(showIdle ? 'Idle Time' : 'Uptime', xCoor, yCoor);
            }
        }

        const fleet_utilization_config = {
            type: 'doughnut',
            data: fleet_utilization_data,
            options: large_doughnut_options,
            //plugins: [fleet_utilization_number,fleet_utilization_label]
        }

	return (
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Doughnut data={fleet_utilization_data} options={large_doughnut_options} plugins={[fleet_utilization_number, fleet_utilization_label]}
                      style={{ width: '100%', height: '100%' }}/>
          </div> );

}

export default UtilizationDonut;

