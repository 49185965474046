import React, { useState, useEffect } from 'react';
import { getClients, addClient, updateClient } from './api/client';
import ItemList from "./ItemList";

function ClientList({ token }) {
  const columns = [ { key: 'name' } ];
  const fields = [ { key: 'name' } ];

  return <ItemList columns={columns} fields={fields} addItem={addClient} getItems={getClients} updateItem={updateClient} itemType="Client" />;
}

export default ClientList;

