import axios from 'axios';

export const getFilters = context => {
    const filterCheck = x => (x !== undefined && x.length == 0) ? undefined : x;
    let fuelTypeFilter = filterCheck(context.fuelTypeFilter);
    let assetFilter = filterCheck(context.assetFilter);
    let groupFilter = filterCheck(context.groupFilter);
    let modelFilter = filterCheck(context.modelFilter);
    let clientFilter = filterCheck(context.clientFilter);
    if (!fuelTypeFilter && !assetFilter && !modelFilter && !groupFilter && !clientFilter)
      return {};
    return { vehicles: context.assets.filter(asset => {
      if (fuelTypeFilter && !fuelTypeFilter.includes(asset.fuelType))
        return false;
      if (modelFilter && !modelFilter.includes(asset.make + ' ' + asset.model))
        return false;
      if (assetFilter && !assetFilter.includes(asset.id))
        return false;
      if (clientFilter && !clientFilter.includes(asset.clientId))
        return false;
      if (groupFilter) {
        const groupSet = new Set(groupFilter);
        if (!asset.groups?.some(group => groupSet.has(group.id)))
          return false;
      }
      return true;
    }).map(x => x.id).join(',') };
}

export const getVehicles = filters => {
    return axios.get('/vehicle', { params: filters })
}

export const getVehicle = (id) => axios.get('/vehicle/' + id)
export const updateVehicle = (id, veh) => axios.put('/vehicle/' + id, veh)
export const addVehicle = (veh) => axios.post('/vehicle', veh)
export const getTripsForVehicle = (id) => axios.get('/vehicle/' + id + '/trips?startDate=2024-04-01&endDate=2024-08-01')
export const getNewTripsForVehicle = (id, date1, date2) => axios.get(`/vehicle/${id}/trips?startDate=${date1}&endDate=${date2}`)
export const getTripsForVehicleByDay = (id, date1, date2) => axios.get(`/vehicle/${id}/tripsByDay?startDate=${date1}&endDate=${date2}`)
export const getDailyTotalByDate = (date1, date2, filters) =>
    axios.get('/vehicle/dailyTotalByDate', { params: { ...filters, startDate: date1, endDate: date2 }})
export const getDailyTotal = (date1, date2, filters) =>
    axios.get('/vehicle/dailyTotal',       { params: { ...filters, startDate: date1, endDate: date2 }})
export const getDailyTotalAll = (date1, date2, filters) =>
    axios.get('/vehicle/dailyTotalAll',    { params: { ...filters, startDate: date1, endDate: date2 }})
export const getTrips = (date1, date2, filters) => axios.get('/vehicle/trips', { params: { ...filters, startDate: date1, endDate: date2 }})

