import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFilters, getVehicles, getTrips, getDailyTotalAll } from './api/vehicle';
import ItemList from "./ItemList";
import { Link } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import Filter from "./Filter";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import { Context } from "./ContextHandler";
import { Select, MenuItem } from '@mui/material';
import PagedList from "./PagedList";

function TripList({ token }) {
  const [trips, setTrips] = useState();
  const [totals, setTotals] = useState();
  const context = useContext(Context);
  const dateRange = context.dateRange;
  const lastRefreshCount = useRef(-1);
  const [definedFilter, setDefinedFilter] = useState(0);
  const [routeFilter, setRouteFilter] = useState([]);
  const [popupVisible, setPopupVisible] = useState();

  const toggleRouteFilter = route => {
    if (routeFilter.includes(route))
       setRouteFilter(routeFilter.filter(y => route !== y));
    else
       setRouteFilter([...routeFilter, route]);
  }

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current && dateRange) {
      lastRefreshCount.current = context.refreshCount;
      const date1 = moment(dateRange[0]).format('YYYY-MM-DD')
      const date2 = moment(dateRange[1]).format('YYYY-MM-DD')
      const filters = getFilters(context);
      getTrips(date1, date2, filters).then(response => setTrips(response.data));
      getDailyTotalAll(date1, date2, filters).then(response => setTotals(response.data));
    }
  }, [context.refreshCount, dateRange]);

  const assetMap = context.assets?.reduce((acc, asset) => { acc[asset.id] = asset; return acc; }, {});

  let offMin = 0, idleMin = 0, decMin = 0, accMin = 0;
  if (totals) {
    totals.forEach(x => {
      if (x.offlineMinutes) {
        offMin  += x.offlineMinutes;
        idleMin += x.idleMinutes;
        decMin  += x.deceleratingMinutes;
        accMin  += x.acceleratingMinutes;
      }
    });
  }

  let avgFuelCost, avgCo2, avgMpg, avgDistance, distPerDay, avgEff, totalDistance, totalFuelConsumed;
  if (totals) {
    const noNaN = x => (x === undefined ? 0 : x)
    const assetCount = totals.reduce((part, a) => a.mpge > 0 ? part+1 : part, 0);
    avgFuelCost = totals.reduce((part, a) => part+noNaN(a.fuelSpend),    0)/assetCount;
    avgCo2      = totals.reduce((part, a) => part+co2PerMile(a),         0)/assetCount;
    totalDistance = totals.reduce((part, a) => part+noNaN(a.distanceDriven), 0);
    totalFuelConsumed = totals.reduce((part, a) => part+noNaN(a.fuelConsumed), 0);
    avgDistance = totalDistance/assetCount;
    distPerDay  = totals.reduce((part, a) => part+noNaN(a.distancePerDay), 0);
    const assetCountEff = totals.reduce((part, a) => a.efficiency !== undefined ? part+1 : part, 0);
    avgEff      = totals.reduce((part, a) => part+noNaN(a.efficiency), 0)/assetCountEff;
    avgMpg = kmToMiles(totalDistance)/(litersToGallons(totalFuelConsumed));
  }
  const eff = (avgEff*100).toFixed(0);

  const columns = [
    { header: 'Asset ID', key: 'name', width: '12%' },
    { header: 'Group', key: 'group' },
    { header: 'Asset Name', key: 'assetName' },
    { header: 'Make / Model', key: 'makeModel' },
    { header: 'Year', key: 'year' },
    { header: 'Fuel', key: 'fuel' },
    { header: 'Route', key: 'route', width: '6%' },
    { header: 'Trip Start', key: 'startTime', width: '6%' },
    { header: 'Trip End', key: 'endTime', width: '6%' },
    { header: 'Trip Duration', key: 'duration' },
    { header: 'Distance Traveled', key: 'milesDriven' },
    { header: 'Utilization', key: 'utilization', sortable: false, displayOnly: true },
    { header: 'Accelerating Minutes', key: 'acceleratingMinutes', downloadOnly: true },
    { header: 'Decelerating Minutes', key: 'deceleratingMinutes', downloadOnly: true },
    { header: 'Idle Minutes',         key: 'idleMinutes', downloadOnly: true },
    { header: 'Offline Minutes',      key: 'offlineMinutes', downloadOnly: true },
    { header: 'Fuel Consumed', key: 'fuelConsumed' },
    { header: 'Gal / Minute', key: 'galPerMinute' },
    { header: 'Max Speed', key: 'maxSpeed' },
    { header: 'Avg MPH', key: 'averageSpeed' },
    { header: 'MPG', key: 'mpg' },
    { header: 'Fuel Cost', key: 'fuelSpend' },
    { header: <>CO<sub>2</sub>e (kg)</>, downloadHeader: 'CO2e (kg)', key: 'co2e' },
    { header: 'Efficiency', key: 'efficiency' }
  ];

  const round  = x => x?.toFixed(2);
  const round0 = x => x?.toFixed(0);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    if (mins) {
      if (mins > 240)
        return txt + ": " + (mins/60).toFixed(0) + " hr";
      return txt + ": " + mins.toFixed(0) + " min";
    }
  };

  const getTripInfo = (trip, key) => {
    if (key == 'name' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset?.name || asset?.licensePlate || asset?.vin;
    }
    if (key == 'year' && assetMap)
      return assetMap[trip.vehicleId].year;
    if (key == 'fuel' && assetMap)
      return assetMap[trip.vehicleId].fuelType;
    if (key == 'makeModel' && assetMap) {
      const asset = assetMap[trip.vehicleId];
      return asset && (asset.make + ' ' + asset.model);
    }
    if (key == 'startTime')
      return moment(trip.startTime).toISOString();
    if (key == 'endTime')
      return moment(trip.endTime).toISOString();
    if (key == 'duration')
      return moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    if (key == 'group')
      return 'ParkWorld';
    if (key == 'upTimeMinutes')
      return trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes;
    if (key == 'galPerMinute')
      return litersToGallons(trip.fuelConsumed) / (trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes);
    if (key == 'milesDriven')
      return kmToMiles(trip.distanceDriven);
    if (key == 'fuelConsumed')
      return litersToGallons(trip.fuelConsumed);
    if (key == 'mpge')
      return mpge(trip);
    if (key == 'mpg')
      return mpg(trip);
    if (key == 'fuelSpend')
      return trip.fuelSpend;
    if (key == 'costPerMile')
      return trip.distanceDriven > 0 && fuelSpendPerMile(trip);
    if (key == 'co2e')
      return co2eInKg(trip);
    if (key == 'co2')
      return trip.distanceDriven > 0 && co2PerMile(trip);
    if (key == 'averageSpeed')
      return kmToMiles(trip.averageSpeed);
    if (key == 'maxSpeed')
      return kmToMiles(trip.maxSpeed);
    return trip[key];
  }

  const getDuration = val => {
    const asMin = Math.floor(val/60);
    const hr = Math.floor(asMin/60)
    const min = asMin-hr*60;
    if (hr == 0)
      return `${min}m`;
    return `${hr}h ${min}m`;
  }

  const getTripCell = (trip, key) => {
    if (key == 'utilization') {
      let totalMins = trip.acceleratingMinutes + trip.deceleratingMinutes + trip.idleMinutes + trip.offlineMinutes;
      let offWidth = 100*trip.offlineMinutes / totalMins;
      let idleWidth = 100*trip.idleMinutes / totalMins;
      let decWidth = 100*trip.deceleratingMinutes / totalMins;
      let accWidth = 100*trip.acceleratingMinutes / totalMins;
      return <td class="chart"><span class="offline" data-title={getLabel("Offline", offWidth, trip.offlineMinutes)} style={{ width: offWidth + '%' }} />
                              <span class="idle" data-title={getLabel("Idle", idleWidth, trip.idleMinutes)} style={{ width: idleWidth + '%' }} />
                              <span class="decelerating" data-title={getLabel("Decelerating", decWidth, trip.deceleratingMinutes)} style={{ width: decWidth + '%' }} />
                              <span class="accelerating" data-title={getLabel("Accelerating", accWidth, trip.acceleratingMinutes)} style={{ width: accWidth + '%' }} />
            </td>;
    }
    let val = getTripInfo(trip, key);
    if (key == 'duration')
      return <td class="number">{getDuration(val)}</td>;
    if (key == 'efficiency')
      return <td class="number">{(val * 100).toFixed(0)}%</td>;
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    if (key == 'startTime' || key == 'endTime') {
      const format = 'M-D h:mma';
      val = moment(val).format(format);
    }
    if (key == 'name') {
      return <td class="text"><Link to={'/trips/' + trip.id}>{val}</Link></td>;
    }
    return <td class="text">{val}</td>;
  }

  let filteredTrips = trips;
  if (definedFilter == 1)
    filteredTrips = trips.filter(x => x.isDefined == true)
  if (definedFilter == 2)
    filteredTrips = trips.filter(x => x.isDefined == false)

  const tripChoices = ["All Trips", "Defined", "Undefined"];
  const routeSet = trips ? new Set(trips.map(x => x.route)) : [];
  let routeChoices = [...routeSet];
  routeChoices.sort();

  const routeFilterText = (routeFilter.length == 0) ? "All Routes" : routeFilter.join('; ');
  if (routeFilter.length > 0)
    filteredTrips = filteredTrips.filter(x => routeFilter.includes(x.route))

  const tableActions = <>
                        <div id="trip-filter" class="dropdown asset-filter"
                            onMouseEnter={ev => setPopupVisible(1)} onMouseLeave={ev => setPopupVisible(0)}>{tripChoices[definedFilter]}
                            {popupVisible == 1 && <div class="dropdown-content">
                                <span>Trips</span>
                                {tripChoices.map((x, ix) => <a href="#" class={definedFilter == ix && "selected"} onClick={ev => setDefinedFilter(ix)}>{x}</a>)}
                            </div>}
                        </div>
                        <div id="route-filter" class="dropdown asset-filter" style={{ width: 400 }}
                            onMouseEnter={ev => setPopupVisible(2)} onMouseLeave={ev => setPopupVisible(0)}>{routeFilterText}
                            {popupVisible == 2 && <div class="dropdown-content">
                                <span>Routes</span>
				<a href="#" class={routeFilter.length == 0 && "selected"} onClick={ev => setRouteFilter([])}>All Routes</a>
                                {routeChoices.map(x => <a href="#" class={routeFilter.includes(x) && "selected"} onClick={ev => toggleRouteFilter(x)}>{x}</a>)}
                            </div>}
                        </div>
                    </>;

  return <>
            <div id="focus">
                <Filter />
                <div id="content">
                    <div id="charts">
                        <div class="individual-chart small">
                            <h4>Efficiency</h4>
                            <div class="doughnut">
                                  <DashboardDonut circleText={eff + '%'} percent={eff} />
                            </div>
                        </div>
                        <div class="individual-chart">
                            <h4>Fleet Utilization</h4>
                            <div class="doughnut">
                                <UtilizationDonut accelerating={accMin} decelerating={decMin} idle={idleMin} offline={offMin} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Fuel Cost</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round0(100*avgFuelCost/kmToMiles(avgDistance)) + '¢'} subText={"¢/Mile"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>CO<sub>2</sub> Emissions</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round(avgCo2/1000)} subText={"kg/Mile"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Fuel Efficiency</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round(avgMpg)} subText={"MPG"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Distance Driven</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round0(kmToMiles(distPerDay))} subText={"Miles/Day"} />
                            </div>
                        </div>
                    </div>
                    <PagedList items={filteredTrips} columns={columns} getItemInfo={getTripInfo} getItemCell={getTripCell} tableActions={tableActions} itemType="Trips" />
                </div>
            </div>
         </>;
}

export default TripList;

