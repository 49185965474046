import React, { useState, useEffect, useContext } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import UserList from './UserList';
import ClientList from './ClientList';
import VehicleList from './VehicleList';
import RouteView from './RouteView';
import Dashboard from './Dashboard';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Context } from "./ContextHandler";

const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  }
];

function DateRangeFilter() {
    const context = useContext(Context);
    const dateRange = context.dateRange;
    const setDateRange = x => {
	 context.dispatch({ type: 'setState', attribute: 'dateRange', payload: x });
	 context.dispatch({ type: 'refresh' });
    };

    return <DateRangePicker style={{ float: 'right', width: 300, fontSize: '12px' }}
                            ranges={predefinedRanges} placement='bottomEnd' onChange={setDateRange} value={dateRange} />;
}

export default DateRangeFilter;

