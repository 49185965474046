import React, { useState, useEffect } from 'react';
import Login from './Login';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import UserList from './UserList';
import ClientList from './ClientList';
import VehicleList from './VehicleList';
import Dashboard from './Dashboard';
import axios from 'axios';
import logo from './images/Accelevate_Logo.svg';
import help from './images/help.svg';
import Filter from "./Filter";
import ContextHandler from "./ContextHandler";

function App() {
  const [token, setToken] = useState(() => localStorage.getItem('token') || '');

  axios.defaults.baseURL = 'https://api.accelevatesolutions.com/';
  //axios.defaults.baseURL = 'http://127.0.0.1:4010/';
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const expiry = parseInt(localStorage.getItem('tokenExpiry'));
    if (expiry < new Date().getTime()) {
      window.location.reload();
      // avoid exception while waiting for reload
      return new Promise(() => {});
    }
    return Promise.reject(error);
  });

  const handleLogin = (token, expiryTime) => {
    setToken(token);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiry', expiryTime*1000);
  };

  const handleLogout = () => {
    setToken('');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
  };

  const tokenExpiry = parseInt(localStorage.getItem('tokenExpiry'));

  return !token || (tokenExpiry < new Date().getTime()) ? <Login onLogin={handleLogin} /> : <ContextHandler handleLogout={handleLogout} />;
}

export default App;

