import { circle } from '@turf/turf';

export const getFeatures = zones => zones?.map(zone => {
    const points = zone.points;
    let poly = [];
    let color = zone.type == 'OpCenter' ? '#F85E7D' : '#F78957';
    if (zone.defined == false)
      color = '#B14EC5';
    const props = { color: color, name: zone.name, id: zone.id };
    if (zone.shape == 'PointRadius') {
      const center = [points[1], points[0]];
      const radius = zone.radius;
      const options = { steps: 50, units: 'meters', properties: props };
      return circle(center, radius, options);
    } else {
      for (let i = 0; i != points.length; i += 2)
         poly.push([points[i+1], points[i]]);
      return { type: 'Feature', geometry: { type: 'Polygon', coordinates: [poly] }, properties: props };
    }
  });

