import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { getZones } from './api/zone';
import ItemList from "./ItemList";
import { Link } from 'react-router-dom';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import DashboardDonut from "./DashboardDonut";
import Filter from "./Filter";
import moment from "moment";
import { kmToMiles, litersToGallons, co2InKg, mpge, mpg, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import { Context } from "./ContextHandler";
import { Select, MenuItem } from '@mui/material';
import PagedList from "./PagedList";
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import type {LineLayer} from 'react-map-gl';
import type {FeatureCollection} from 'geojson';
import DrawControl from './draw-control';
import { getFeatures } from './util/zones';

function ZonePage({ token }) {
  const [zones, setZones] = useState();

  // can't create zones yet but will add later
  const [createState, setCreateState] = useState(0);
  const [newFeature, setNewFeature] = useState({});

  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const mapRef = useRef(null);
  const [hoverInfo, setHoverInfo] = useState();

  const onCreate = React.useCallback(e => {
     setNewFeature(e.features[0]);
     setCreateState(2);
  }, []);

  const onUpdate = React.useCallback(e => {
     setNewFeature(e.features[0]);
  }, []);

  const onDelete = React.useCallback(e => {
  }, []);

    // State for storing mouse position
    const [mousePosition, setMousePosition] = useState(null);

    // Mapbox token
    const mapboxToken = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

    // Mapbox GL initial viewport settings
    const [viewState, setViewState] = useState({
        latitude: 34, // latitude[0],
        longitude: -118.4, // longitude[0],
        zoom: 10
    });

    // Handler for mouse move event on the graph
    const handleMouseMove = (event) => {
    };

  const filterCheck = x => (x !== undefined && x.length == 0) ? undefined : x;
  let clientFilter = filterCheck(context.clientFilter);
  const filteredZones = (clientFilter) ? (zones?.filter(z => clientFilter.includes(z.clientId))) : zones;

  useEffect(() => {
    if (context.refreshCount != lastRefreshCount.current) {
      lastRefreshCount.current = context.refreshCount;
      getZones().then(response => setZones(response.data));
    }
  }, [context.refreshCount]);

  const features = getFeatures(filteredZones);
 
  const zoomIn = zlist => {
    if (zlist.length == 0)
      return;
    const pad = (zlist.length == 1) ? .002 : .005;
    const longitudes = zlist.flatMap(zone => zone.points.filter((_, index) => index % 2 == 1));
    const latitudes  = zlist.flatMap(zone => zone.points.filter((_, index) => index % 2 == 0));
    const bound1 = [Math.min(...longitudes)-pad, Math.min(...latitudes)-pad];
    const bound2 = [Math.max(...longitudes)+pad, Math.max(...latitudes)+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 500 });
    }
  };

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!zones)
       return;
    zoomIn(filteredZones);
  }, [zones, context.clientFilter]);

  const geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features
  };

  const new_geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: newFeature && [newFeature]
  };

  const onHover = useCallback(e => {
    const features = e.features;
    const hoveredFeature = features && features[0];
    if (hoveredFeature)
      setHoverInfo({
        longitude: e.lngLat.lng,
        latitude: e.lngLat.lat,
        properties: hoveredFeature.properties
      });
    else
      setHoverInfo(null);
  }, []);

  const columns = [
    { header: 'Zone Name', key: 'name', width: '12%' },
    { header: 'Zone Shape', key: 'shape', width: '8%' },
    { header: 'Zone Type', key: 'type', width: '8%' },
    { header: 'Client', key: 'client' },
    { header: 'Address', key: 'address' }
  ];

  const getItemInfo = (item, key) => {
    if (key == 'coordinates')
      return item['points'].join(', ');
    if (key == 'client')
      return context.clients?.find(x => x.id == item['clientId'])?.name;
    if (key == 'shape')
      return item[key] == 'Polygon' ? 'polygon' : 'point-radius';
    return item[key];
  }

  const round = x => x?.toFixed(2)

  const getItemCell = (item, key) => {
    let val = getItemInfo(item, key);
    if (typeof val === 'number') {
      if (!Number.isFinite(val))
        val = undefined;
      else if (!Number.isInteger(val))
        val = round(val);
      return <td class="number">{val}</td>;
    }
    let style;
    if (hoverInfo?.properties.id == item.id)
      style = { backgroundColor: '#ffffa0' };
    if (key == 'name')
      return <td class="text" style={style}><a href="#" onClick={ev => zoomIn([item])}>{val}</a></td>;
    if (key == 'type') {
      if (item.type == 'OpCenter')
        return <td class="op-center" style={style} />;
      if (item.defined === false)
        return <td class="undefined" style={style} />;
      return <td class="tracking" style={style} />;
    }
    return <td class="text" style={style}>{val}</td>;
  }

  return <>
            <div id="focus">
		<Filter title="Route Zones" clientFilterOnly={true} rightSide={(
                    <ul class="legend">
                        <li class="op-center"><span></span>Op Center</li>
                        <li class="tracking"><span></span>Tracking</li>
                        <li class="undefined"><span></span>Undefined</li>
                    </ul>
                )} />
                <div id="map">
		    <Map
			{...viewState}
			mapboxAccessToken={mapboxToken} ref={mapRef}
			onMove={evt => setViewState(evt.viewState)}
			mapStyle="mapbox://styles/mapbox/streets-v9"
			interactiveLayerIds={['polygon-layer']}
                        onMouseMove={onHover}
		    >
			<Source id="polygon-data" type="geojson" data={geojson}>
			  <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': ['get', 'color'], 'fill-opacity': 0.9, }} />
			  <Layer id="polygon-outline" type="line" paint={{ 'line-color': '#000', 'line-width': 2, }} />
			</Source>
			{createState == 0 && newFeature && <Source id="polygon-data-new" type="geojson" data={new_geojson}>
			  <Layer id="polygon-layer-new" type="fill" paint={{ 'fill-color': '#ff8000', 'fill-opacity': 0.5, }} />
			  <Layer id="polygon-outline-new" type="line" paint={{ 'line-color': '#000', 'line-width': 3, }} />
			</Source>}
			{createState > 0 && <DrawControl
			  position="top-left"
			  displayControlsDefault={false}
			  controls={{
			    polygon: true,
			    trash: true
			  }}
			  defaultMode="draw_polygon"
			  onCreate={onCreate}
			  onUpdate={onUpdate}
			  onDelete={onDelete}
			/>}
                            {hoverInfo && (
                              <Popup
                                longitude={hoverInfo.longitude}
                                latitude={hoverInfo.latitude}
                                closeButton={false}
                                closeOnClick={false}
                                anchor="top"
                              >
                                <div>{hoverInfo.properties.name}</div>
                              </Popup>
                            )}
		    </Map>
		</div>
                <div id="content">
		    <PagedList items={filteredZones} columns={columns} getItemInfo={getItemInfo} getItemCell={getItemCell} itemType="Zones" />
		</div>
            </div>
         </>;
}

export default ZonePage;

