import React, { useState, useEffect } from 'react';
import { getVehicles, addVehicle, updateVehicle } from './api/vehicle';
import ItemList from "./ItemList";

// did you mean to load AssetList?

function VehicleList({ token }) {
  const columns = [ { key: 'make' }, { key: 'model' }, { key: 'year' }, { header: 'VIN', key: 'vin' }, { key: 'name' }, { header: 'VCG ID', key: 'vcgId' }, { header: 'Geotab Id', key: 'geotabId' } ];
  const fields = [ { key: 'make' }, { key: 'model' }, { key: 'year' }, { label: 'VIN', key: 'vin' }, { key: 'name' }, { label: 'VCG ID', key: 'vcgId' }, { label: 'Geotab Id', key: 'geotabId' } ];

  return <ItemList columns={columns} fields={fields} addItem={addVehicle} getItems={getVehicles} updateItem={updateVehicle} itemType="Vehicle" />;
}

export default VehicleList;

