export const kmToMiles = km => km/1.609;
export const litersToGallons = liters => liters/3.785;
export const fuelSpendPerMileCents = totals => 100 * totals?.fuelSpend / kmToMiles(totals?.distanceDriven);
export const fuelSpendPerMile = totals => totals?.fuelSpend / kmToMiles(totals?.distanceDriven);
//export const co2PerMile = totals => (totals?.fuelConsumed * 1555 / kmToMiles(totals?.distanceDriven))
export const co2PerMile = totals => {
   if (!totals) return 0;
   if (!totals.co2Generated || !totals.distanceDriven) return 0;
   return totals.co2Generated / kmToMiles(totals.distanceDriven);
}
//export const co2eInKg = totals => totals?.fuelConsumed*1.557;
export const co2eInKg = totals => totals?.co2eGenerated/1000;
//export const mpge = totals => kmToMiles(totals?.distanceDriven)/(litersToGallons(totals?.fuelConsumed)/1.3666);
export const mpg = totals => {
  if (!totals)
    return;
  if (!totals.fuelConsumed)
    return;
  return kmToMiles(totals.distanceDriven)/(litersToGallons(totals.fuelConsumed));
}
export const mpge = totals => totals?.mpge
export const milesPerDay = totals => kmToMiles(totals?.distancePerDay);
