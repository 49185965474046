import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { getTrip, getTelemetry } from './api/trip';
import { getZones } from './api/zone';
import ItemList from "./ItemList";
import moment from "moment";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { kmToMiles, litersToGallons, co2InKg, mpg, mpge, fuelSpendPerMile, co2eInKg, co2PerMile } from './util/formulas';
import DateRangeFilter from "./DateRangeFilter";
import { Context } from "./ContextHandler";
import PagedList from "./PagedList";
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import UtilizationDonut from "./UtilizationDonut";
import AssetListDonut from "./AssetListDonut";
import { getFeatures } from './util/zones';

import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement );

function TripDetails({ token }) {
  const { id } = useParams();
  const [trip, setTrip] = useState();
  const [telemetry, setTelemetry] = useState();
  const [zones, setZones] = useState([]);
  const navigate = useNavigate();
  const context = useContext(Context);
  const lastRefreshCount = useRef(-1);
  const [hoverInfo, setHoverInfo] = useState();
  const mapRef = useRef(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v9');

  const handleStyleChange = (event) => {
    const selectedStyle = event.target.value;
    setMapStyle(selectedStyle);
  };

  const latitudes = telemetry && (telemetry.items[0].values);
  const longitudes = telemetry && (telemetry.items[1].values);

  // Mapbox token
  const mapboxToken = 'pk.eyJ1IjoicGZhY2NlbCIsImEiOiJjbHV1a3Y3YmkwMDRpMmlwYm9xN3AwNDI0In0.DgtjwUVyRSS7vq3jaXV4Jg';

  // Mapbox GL initial viewport settings
  const [viewState, setViewState] = useState({
      latitude: 32, // latitude[0],
      longitude: -117, // longitude[0],
      zoom: 10
  });

  // Calculate bounding box and fit map to marker
  useEffect(() => {
    if (!telemetry)
       return;
    const pad = .01;
    const bound1 = [Math.min(...longitudes)-pad, Math.min(...latitudes)-pad];
    const bound2 = [Math.max(...longitudes)+pad, Math.max(...latitudes)+pad];
    const bounds = new mapboxgl.LngLatBounds(bound1, bound2);

    if (mapRef.current) {
      mapRef.current.fitBounds(bounds, { duration: 0 });
    }
  }, [telemetry]);

  useEffect(() => {
     if (context.refreshCount != lastRefreshCount.current) {
	getTrip(id).then(result => {
            const t = result.data;
            setTrip(t);
            getTelemetry(t.vehicleId, ['latitude', 'longitude'], t.startTime, t.endTime).then(result => {
               setTelemetry(result.data);
            });
            getZones(t.clientId).then(response => setZones(response.data));
        });
     }
  }, [context.refreshCount]);

  const getLabel = (txt, val, mins) => {
    //return txt + ": " + val.toFixed(0) + '%';
    return txt + ": " + mins.toFixed(0) + " min";
  };

  const round = x => x?.toFixed(2);
  const round1 = x => x?.toFixed(1);

        const overall_grade_data = {
            datasets: [{
              data: [78, 22],
              backgroundColor: [
                '#F85E7D','#E3E2EC'
              ],
              borderWidth: 0
            }]
        }
        
        const overall_grade_label = {
            id: 'doughnutLabel',
            beforeDatasetDraw (chart, args, pluginOptions) {
                const {ctx, data} = chart;

                ctx.save();
                const xCoor = chart.getDatasetMeta(0).data[0].x;
                const yCoor = chart.getDatasetMeta(0).data[0].y;
                ctx.font = 'bold 3vi Helvetica, "Helvetica Neue", Arial, sans-serif';
                ctx.fillStyle = '#000000';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle'
                ctx.fillText('C+', xCoor, yCoor);
            }
        }
        
        const large_doughnut_options = {
            cutout: '65%',
            responsive: false,
            plugins: {
                tooltip: {
                    bodyFont: {
                        size: 24
                    }
                }
            }
        }

        const overall_grade_config = {
            type: 'doughnut',
            data: overall_grade_data,
            options: large_doughnut_options,
            plugins: [overall_grade_label]
        }

  const avgCo2 = 0, avgMpge = 0, avgDistance = 0;
  const round0 = x => x?.toFixed(0)

  const tripTime = trip && moment(trip.startTime).format('M-D @ h:mmA')
  let tripLength;
  if (trip) {
    const dur = moment.duration(moment(trip.endTime).diff(moment(trip.startTime))).asMilliseconds() / 1000;
    tripLength = (dur / 60).toFixed(0) + 'm ' + (dur % 60) + 's';
  }

  const geojson: FeatureCollection = telemetry && {
    type: 'FeatureCollection',
    features: [
      {type: 'Feature', geometry: {type: 'LineString', coordinates: latitudes.map((lat, index) => [longitudes[index], lat]) }}
    ]
  };

  const layerStyle: LineLayer = {
    id: 'route',
    type: 'line',
    source: 'route',
	      'layout': {
		  'line-join': 'round',
		  'line-cap': 'round'
	      },

    paint: {
      'line-width': 3,
      'line-color': '#007cbf'
    }
  };

  const features = getFeatures(zones);
 
  const zone_geojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: features
  };

  const onHover = useCallback(e => {
    const features = e.features;
    const hoveredFeature = features && features[0];
    if (hoveredFeature)
      setHoverInfo({
	longitude: e.lngLat.lng,
	latitude: e.lngLat.lat,
	properties: hoveredFeature.properties
      });
    else
      setHoverInfo(null);
  }, []);

console.log(hoverInfo);

  return <>
            <div id="focus">
                <div id="filters">
                    <div id="company"><a href="#" class="back-button" onClick={ev => navigate('/trips')} />{trip?.vehicleName} | Trip {tripTime}, {tripLength}</div>
                </div>
                <div id="content">
                    <div id="charts">
                        <div class="individual-chart">
                            <h4>Fleet Utilization</h4>
                            <div class="doughnut">
                                <UtilizationDonut accelerating={trip?.acceleratingMinutes} decelerating={trip?.deceleratingMinutes} idle={trip?.idleMinutes} offline={trip?.offlineMinutes} showIdle={true} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Fuel Cost</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={'$' + round(trip?.fuelSpend)} subText={"USD"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>CO<sub>2</sub> Emissions</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round0(trip?.co2Generated / 1000)} subText={"kg"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Fuel Efficiency</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round(mpg(trip))} subText={"MPG"} />
                            </div>
                        </div>
                        <div class="individual-chart small">
                            <h4>Distance Driven</h4>
                            <div class="doughnut">
                                <AssetListDonut mainText={round1(kmToMiles(trip?.distanceDriven))} subText={"Miles"} />
                            </div>
                        </div>
                    </div>
                    <div id="map" style={{ position: 'relative' }}>
		      <div style={{ position: 'absolute', zIndex: 100, backgroundColor: 'white', borderRadius: 5, padding: 3 }}>
			<label>
			  <input
			    type="radio"
			    value="mapbox://styles/mapbox/streets-v9"
			    checked={mapStyle === 'mapbox://styles/mapbox/streets-v9'}
			    onChange={handleStyleChange}
			  />
			  Streets
			</label>
			<label>
			  <input
			    type="radio"
			    value="mapbox://styles/mapbox/satellite-v9"
			    checked={mapStyle === 'mapbox://styles/mapbox/satellite-v9'}
			    onChange={handleStyleChange}
			  />
			  Satellite
			</label>
		      </div>

			<Map
				{...viewState}
				mapboxAccessToken={mapboxToken} ref={mapRef}
				onMove={evt => setViewState(evt.viewState)}
				mapStyle={mapStyle} interactiveLayerIds={['polygon-layer']}
      				onMouseMove={onHover}>
			  <Source id="route" type="geojson" data={geojson}>
			    <Layer {...layerStyle} />
			  </Source>
			  <Source id="polygon-data" type="geojson" data={zone_geojson}>
			    <Layer id="polygon-layer" type="fill" paint={{ 'fill-color': '#ffff00', 'fill-opacity': 0.3, }} />
			  </Source>
			    {hoverInfo && (
			      <Popup
				longitude={hoverInfo.longitude}
				latitude={hoverInfo.latitude}
				closeButton={false}
				closeOnClick={false}
				anchor="top"
			      >
				<div>{hoverInfo.properties.name}</div>
			      </Popup>
			    )}
			</Map>
		    </div>
                </div>
            </div>
         </>;
}

export default TripDetails;

